import { configureStore } from "@reduxjs/toolkit"

// import testReducer from "./TestStore"
import authReducer from "../ui/user/auth/reducer"
import playerbetReducer from '../ui/betbar/reducer'
import resultReducer from '../ui/result/reducer'
import historyReducer from '../ui/history/reducer'


const store = configureStore({
    reducer: {
        // test: testReducer,
        auth: authReducer,
        playerbet: playerbetReducer,
        history: historyReducer,
        result: resultReducer
    },
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         serializableCheck: false,
    //     }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
