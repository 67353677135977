import { Endpoints } from "../const/Api"

interface Params extends Omit<RequestInit, 'body'> {
    body?: Record<string, any>
}

export const client = async (endpoint: string, { body, ...customConfig }: Params = {}) => {
    const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' };

    const config: RequestInit = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    }

    if (body) {
        config.body = JSON.stringify(body)
    }

    let data
    try {

        // if(document.hidden || document.visibilityState=="hidden"){
        //     window.location.href = "/";
        //    setTimeout(() => {
        //        window.stop()
        //    }, 0);
        // }
        try {
            // @ts-ignore
            navigator.wakeLock && await navigator.wakeLock.request('screen');
        } catch (error) {

        }

        const response = await window.fetch(endpoint, config)
        data = await response.clone().json()

        if (response.ok) {
            // Return a result object similar to Axios
            return {
                status: response.status,
                data,
                headers: response.headers,
                url: response.url,
            }
        }

        throw new Error(data ? data?.message : response.statusText)
    }
    catch (err: any) {
        return Promise.reject(err)
    }
}

client.get = (endpoint: string, customConfig = {}) => {
    return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = (endpoint: string, body: Record<string, any>, customConfig = {}) => {
    return client(endpoint, { ...customConfig, body })
}