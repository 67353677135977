import { useState, useEffect } from 'react'

import BackgroundLandscape from '../app/landscape/bg.png'
import BackgroundPortrait from '../app/portrait/bg.png'
import LoadingGif from '../app/loading.gif'

import BetButton from '../betbar/bet.png'
import RectangleButton from '../betbar/rect.png'
import DropdownButton from '../betbar/dropdown/dropdown.png'
import bar_form_landscape from '../betbar/bar-form-landscape.png'
import bar_form_portrait from '../betbar/bar-form-portrait.png'

import GameLandscape from '../game/top_16_rows.png'
import GamePortrait from '../game/bg-portrait.png'
import FrameLandscape from '../game/bottom_16_rows.png'
import FramePortrait from '../game/frame-portrait.png'
import PerentageBar from '../game/hole/percentage/bg.png'

import PopOutBar from '../history/out/bg.png'
import PopOutBMP from '../history/out/bmp.png'
import PopOutClose from '../history/out/close.png'
import PopOutIconBet from '../history/out/icon_bet.png'
import PopOutIconCopy from '../history/out/icon_copy.png'
import PopOutIconLink from '../history/out/icon_link.png'
import PopOutPlay from '../history/out/play.png'

import TableBar from '../history/table/bg.png'
import TableHead from '../history/table/bg-head.png'
import TableX from '../history/table/x.png'

import SettingBar from '../setting/bg_setting.png'
import VolumeBar from '../setting/bg-range.png'
import IconFullscreen from '../setting/icon_fullscreen.png'
import IconNotFullscreen from '../setting/icon_notfullscreen.png'
import IconTutorial from '../setting/icon_tutorial.png'
import IconVolume from '../setting/icon_volume.png'

import FooterBalance from '../user/footer/balance.png'
import FooterWinLandscape from '../user/footer/bg-win-landscape.png'
import FooterWinPortrait from '../user/footer/bg-win-portrait.png'
import FooterIconUser from '../user/footer/user.png'



const usePreloadImage = () => {
    const [isReady, setIsReady] = useState(false)
    const [progess, setProgress] = useState(0)

    useEffect(() => {
        setProgress(0)

        const imgs = [
            // App
            BackgroundLandscape,
            BackgroundPortrait,
            LoadingGif,

            // BetBar
            BetButton, RectangleButton, DropdownButton, bar_form_landscape, bar_form_portrait,

            // Game
            GameLandscape, GamePortrait, FrameLandscape, FramePortrait, PerentageBar,

            // History
            PopOutBar, PopOutBMP, PopOutClose, PopOutIconBet, PopOutIconCopy, PopOutIconLink, PopOutPlay,
            TableBar, TableHead, TableX,

            // Setting
            SettingBar, VolumeBar, IconFullscreen, IconNotFullscreen, IconTutorial, IconVolume,

            // Footer
            FooterBalance, FooterWinLandscape, FooterWinPortrait, FooterIconUser
        ]
        const onePart = 50 / imgs.length

        cacheImages(imgs, onePart)
    }, [])

    const cacheImages = async (srcArray: string[], onePart: number) => {
        const promises = await srcArray.map(src => {
            return new Promise( (resolve, reject) =>{
                const img = new Image()
                img.src = src

                img.onload = () => {
                    setProgress(prev => prev += onePart)
                    resolve(null)
                }
                img.onerror = () => {
                    setProgress(prev => prev += onePart)
                    reject()
                }
            })
        })

        await Promise.all(promises)

        setIsReady(true)
    }
    return { isReady, progess }

}

export default usePreloadImage