import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { BetBarState, BetData } from "./type";

const initialState: BetBarState = {
    amount: 0,
    round_id: 0,
    ball_list: [1, 5, 10],
    risk_list: ["Low", "Medium", "High"],
    row_list: [8, 10, 12],
    last_bet: [],
    total_amount: 0,
    total_amount_auto: 0,
    rows: 8,
    balls: 1,
    risk: "Low"
}


export const betbarSlice = createSlice({
    name: 'betbar',
    initialState,
    reducers: {
        changeList: (state, action: PayloadAction<any>) => {
            state.ball_list = action.payload.ball_list || state.ball_list
            state.risk_list = action.payload.risk_list || state.risk_list
            state.row_list = action.payload.row_list || state.row_list
        },
        changeRow: (state, action: PayloadAction<number>) => {
            state.rows = action.payload
        },
        changeBall: (state, action: PayloadAction<number>) => {
            state.balls = action.payload
            state.total_amount = state.amount * state.balls
            // state.total_amount_auto = state.amount * state.balls


        },
        changeRisk: (state, action: PayloadAction<string>) => {
            state.risk = action.payload
        },
        changeAmount: (state, action: PayloadAction<number>) => {
            state.amount = action.payload
            state.total_amount = action.payload * state.balls
            // state.total_amount_auto = action.payload * state.balls

        },
        updateTotalAmountAuto: (state, action: PayloadAction<number>) => {

            if (!action.payload)
                state.total_amount_auto = 0
            else state.total_amount_auto += action.payload


        },
        updateTotalAmountManual: (state, action: PayloadAction<number>) => {

            if (!action.payload)
                state.total_amount_auto = 0
            else state.total_amount_auto += action.payload


        },
        setLastBet: (state, action: PayloadAction<BetData[]>) => {
            const betArr = action.payload

            betArr.forEach(bet => {
                const { amount, balls, risk, rows } = bet

                state.last_bet.push({ balls, risk, rows, amount, round_id: state.round_id })
            })

        },
    }
})


export const {
    changeRow,
    changeRisk,
    setLastBet,
    changeAmount,
    changeBall,
    changeList,
    updateTotalAmountAuto,
    updateTotalAmountManual
} = betbarSlice.actions

export const getTotalBets = (state: RootState) => state.playerbet.total_amount

export default betbarSlice.reducer