import { useEffect } from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import useOrientation from "../../hook/Orientation";

const AutoAjustText = ({ text = "", className = "" }) => {
  const { t, i18n } = useTranslation();
  const orientation = useOrientation();

  const jqEl = $(".footer-total-win-content");
  useEffect(() => {

    const threshold = 15;
    // if (jqEl && orientation === "landscape") {
    //   jqEl.each(function () {
    //     let fs = parseInt(jqEl.css("font-size"));
    //       // @ts-ignore
    //       while (jqEl.height() > threshold) {
    //         jqEl.css({ "font-size": fs-- });
    //       }
    //       jqEl.height(threshold);
    //   });
    // }
    // if (jqEl && orientation === "portrait") {
    //   jqEl.css({ "font-size": "" })
    //   jqEl.height("");
    // }
  }, [text, orientation, jqEl]);

  return (
    <>
      <div className={className}>
        <span className="footer-total-win-text capitalize">
          {t("footer.win")}:
        </span>
        <span className="footer-total-win-value">{text}</span>
      </div>
    </>
  );
};

export default AutoAjustText;
