import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import BG from "./bg.png";
import Close from "./close.png";
import Bet from "./icon_bet.png";
import BGBMP from "./bmp.png";

import "./index.scss";
import { useAnimateOpening } from "../../betbar/dropdown";
import { motion } from "framer-motion";
import { History } from "../type";
import { getColor } from "../../color";
import { formatCurrency } from "../../../tool/money";
import { useAppSelector } from "../../../store/hook";
import { getPlayer } from "../../user/auth/reducer";
import { Player } from "../../user/auth/type";
interface OutIProps extends ReturnType<typeof useAnimateOpening> {
  dataHistory: History
  setOutIdx: (a:number)=>void
}
const Out = ({
  handleOpen,
  active,
  containerRef,
  motionVariant,
  dataHistory,
  setOutIdx,
}: OutIProps) => {
  const { t, i18n } = useTranslation();
  const player = useAppSelector(getPlayer) as Player;
  
  const language = i18n.language;
const handleClose =  ()=>{
  handleOpen()
  setOutIdx(-1)
}
const { player_balance, player_name, player_currency } = player;

const handleFormatCurrency = (amount: number): string =>
formatCurrency(amount, player_currency, language);
const [ color, setColor ] = useState();

  useEffect(()=>{
   (async()=>{
    setColor(await getColor())
  })()
  }, [])

  return (
    <motion.div
      initial="close"
      animate={active ? "open" : "close"}
      variants={motionVariant}
      // @ts-ignore
      ref={containerRef}
      className="out"
    >
      <div className="black" 
      onClick={()=>handleClose()} 
      ></div>
      <img className="out-bg" alt="" src={BG} />
      <div className="out-title">
        <div className="title-bet">
          <img className="title-bet-icon" alt="" src={Bet} />
          <span>{t('history.out.bet')}</span>
        </div>
        <img className="title-close" alt="" src={Close} onClick={()=>handleClose()} />
      </div>
      <div className="count">
        <span>{t('history.out.plinko')} : {dataHistory.round_id}</span>
        {/* <div className="tool">
          <img className="tool-icon" alt="" src={Copy} />
          <img className="tool-icon" alt="" src={Link} />
        </div> */}
      </div>
      <div className="placed">
        {/* <span>{t('history.out.placed_by')} Johndoe</span> */}
        <span> {t('history.out.placed_on')} </span>
        <span> {dataHistory.created_date} </span>
      </div>
      <div className="bmp">
        <img className="bmp-bg" alt="" src={BGBMP} />
        <div className="bmp-container">
          <div className="bmp-bet">
            <span className="title">{t('history.out.bet')}</span>
            <span className="value">{handleFormatCurrency(dataHistory.data.detail_bet.amount)}</span>
          </div>
          <div className="bmp-multiplier">
            <span className="title">{t('history.table.multiplier')}</span>
            <span className="value">{dataHistory.prize.value}x</span>
          </div>
          <div className="bmp-payout">
            <span className="title">{t('history.table.payout')}</span>
            <span className="value">{handleFormatCurrency(dataHistory.data.total_win)}</span>
          </div>
        </div>
      </div>
      <div className="multiplier">
        <div className="multiplier-bg" style={{backgroundColor: `rgba(${color && color[dataHistory.data.detail_bet.rows][dataHistory.prize.position-1]},1)`, width: dataHistory.prize.value>=1000?'9rem': dataHistory.prize.value>=100?'7rem':'5rem'}} />
        <div className="multiplier-label">{dataHistory.prize.value}x</div>
      </div>
      <div className="out-form">
        <div className="form-group">
          <label htmlFor="risk">{t('betbar.risk')}</label>
          <input type="text" id="risk" name="risk" defaultValue={dataHistory.data.detail_bet.risk.charAt(0).toUpperCase().concat(dataHistory.data.detail_bet.risk.substring(1))} onFocus={({target})=>target.blur()} />
        </div>
        <div className="form-group" style={{marginTop:'7rem'}}>
          <label htmlFor="rows">{t('betbar.rows')}</label>
          <input type="text" id="rows" name="rows" defaultValue={dataHistory.data.detail_bet.rows}  onFocus={({target})=>target.blur()} />
        </div>
      </div>
    </motion.div>
  );
};

export default Out;
