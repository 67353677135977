import React from "react";

import { useTranslation } from "react-i18next";
import BG from "./bg.png";
import "./index.scss";
interface PercentageIProps {
  profit: string;
  chance: string;
}

const Percentage = ({ profit, chance }: PercentageIProps) => {
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  return (
    <div className="percentage">
      <img className="percentage-bg" alt="" src={BG} />
      <div className="percentage-form">
        <div className="form-group" style={{}}>
          <label htmlFor="profit">
            {t("game.hole.percentage.profit_on_win")}
          </label>
          <input type="text" id="profit" name="profit" defaultValue={profit} />
        </div>
        <div className="form-group" style={{}}>
          <label htmlFor="profit">{t("game.hole.percentage.chance")}</label>
          <input type="text" id="profit" name="profit" defaultValue={chance} />
        </div>
      </div>
    </div>
  );
};

export default Percentage;
