// import M from "./matter.min.js";
import sound from "../../sound";
import { Result } from "../result/type";
import Matter from "matter-js";

const Plinko: any = {};

const baseIterations = 10;
// const Matter: any = M;
const CATEGORY_BALL = 0b0001;
const CATEGORY_PEG = 0b0010;

let dropBallCheck: any;
let row: any
let gotCollideData: any = undefined;
let gotSpaceData: any = undefined;
let gotWallData: any = undefined;
Plinko.create = (element: any, r: number) => {
    row = r;

    const Engine = Matter.Engine,
        Render = Matter.Render,
        Runner = Matter.Runner,
        World = Matter.World,
        Bodies = Matter.Bodies;


    // create engine
    const engine = Engine.create({
        // enableSleeping: true,
        positionIterations: baseIterations,
        velocityIterations: baseIterations,
        constraintIterations: baseIterations,

        // gravity: {x: 0, y: 0.1},
    }),
        world = engine.world;

    // create renderer

    const render = Render.create({
        element,
        engine: engine,
        options: {
            wireframes: false,
            showSleeping: false,
            width: 630,
            height: 650,
            background: "transparent",

            // showCollisions: true,
            // showSeparations: true,
            // // showAxes: true,
            // wireframes: true,
            // showDebug: true
        },
    });

    // create runner
    const runner1 = Runner.create();
    const runner2 = Runner.create();
    runner1.isFixed = true;
    // runner2.delta = 0.0000005 * runner1.delta;
    // runner1.

    const tick: any = {};

    const tickGSAPWhileHidde =
        (value: boolean): any => {
            if (value === false) {
                document.removeEventListener("visibilitychange", tick.fn);
                window.removeEventListener("blur", tick.fnBlur);
                window.removeEventListener("focus", tick.fnFocus);

                // clearInterval(tick.iosSleepPreventInterval);
                clearTimeout(tick.id);
                clearTimeout(tick.blur);
                return;
            }
            const onChange = () => {
                clearTimeout(tick.id);
                clearTimeout(tick.blur);

                // clearInterval(tick.iosSleepPreventInterval);
                if (document.hidden || document.visibilityState == "hidden") {
                    // tick.id = setInterval(() => {
                    // tick.iosSleepPreventInterval = setInterval(() => {
                    //     window.location.href = "/";
                    //         window.stop()
                    // }, 20000);


                    const update = () => {
                        console.log("matter update on", document.hidden, document.visibilityState);
                        Engine.update(engine, 1000 / 60);
                        tick.id = setTimeout(update, 1000 / 60);
                    }
                    tick.id = setTimeout(update, 1000 / 60);
                } else {
                    console.log("matter update");
                }
            };
            const onBlur = () => {
                clearTimeout(tick.blur);
                clearTimeout(tick.id);

                // const iframe = document.createElement('iframe');
                // iframe.src="./background.html";
                // iframe.style.display="none";
                // document.body.append(iframe);
                // if(!document.querySelector('.iPhone'))return;
                // const update = () => {
                //     console.log("matter update on blur");
                //     Engine.update(engine, 1000 / 60);
                //     tick.blur = setTimeout(update, 0);
                // }
                // tick.blur = setTimeout(update,0);
            }
            const onFocus = () => {
                document.querySelectorAll('iframe').forEach((i) => {
                    i.parentNode?.removeChild(i);
                    i.remove();
                });
                clearTimeout(tick.blur);
            }
            document.addEventListener("visibilitychange", onChange);
            window.addEventListener("blur", onBlur);
            window.addEventListener("focus", onFocus);
            tick.fn = onChange;
            tick.fnBlur = onBlur;
            tick.fnFocus = onFocus;

            onChange();
        }

    tickGSAPWhileHidde(true);
    const orientation = document.querySelector(".game-area.landscape")
        ? "landscape"
        : "portrait";
    Render.run(render);

    Runner.run(runner1, engine);
    Runner.run(runner2, engine);
    // Matter.Engine.update(engine, 1000 / (60))

    // Runner.run(runner3, engine);
    // setInterval(function () {
    //   Engine.update(engine, 1000 / 60);
    // }, 1000 / 15);
    // if (row && orientation=="portrait") {
    //   setInterval(function () {
    //     Engine.update(engine, 1000 / 60);
    //   }, 1000 / 0.2501125);
    // }
    // const allRunner = Array.from(Array(1).keys()).map((_, idx) => {
    //     let i: any;
    //     const update = (i: any) => () => {
    //         Matter.Engine.update(engine, 1000 / (120))
    //         i = window.requestAnimationFrame(update(i))
    //     }
    //     i = window.requestAnimationFrame(update(idx))
    //     return i;
    // })

    let dropBall: any;
    let spaceSetting: any, global_collideSetting: any;
    let pegs: Matter.Body[];
    let wall: Matter.Body[];

    const finish = Bodies.rectangle(250, 630, 1000, 50, {
        isStatic: true,
        label: `finish`,
        collisionFilter: {
            category: CATEGORY_PEG,
        },
        render: {
            fillStyle: "#ffffff",
            visible: false,
        },
    });

    World.add(
        world,
        finish
    );




    const data = {
        changeRow: (r: number) => {
            row = r;

            // requestAnimationFrame(()=>{
            pegs && pegs.length && pegs.map((peg) => Matter.World.remove(engine.world, peg))
            wall && wall.length && wall.map((peg) => Matter.World.remove(engine.world, peg))
            // })
            pegs = [];
            wall = [];
            (async () => {
                spaceSetting = gotSpaceData || await (await fetch("/space.json")).json();
                gotSpaceData = spaceSetting;
                const collideSetting = gotCollideData || await (await fetch("/collide.json")).json();
                gotCollideData = collideSetting
                global_collideSetting = collideSetting;
                const dataSpace: any = spaceSetting;

                let spacingY = dataSpace[row][orientation].y;
                let spacingX = dataSpace[row][orientation].x;
                let size = dataSpace[row][orientation].size;

                dropBall = (idx: number, firstPath: "L" | "R") => {
                    const middle = 300 - size / 2;
                    const left = middle - spacingX + size / 2;
                    const right = middle + spacingX - size / 2;
                    const dataFirstPath = {
                        L: [
                            { x: left, changeTo: "R" },
                            { x: middle, changeTo: "L" },
                            { x: middle - spacingX / 2, changeTo: "" },
                        ],
                        R: [
                            { x: right, changeTo: "L" },
                            { x: middle, changeTo: "R" },
                            { x: middle + spacingX / 2, changeTo: "" },
                        ],
                    };
                    const randomeSelectDataDirstPath =
                        dataFirstPath[firstPath][
                        Number(randomNumber(0, dataFirstPath[firstPath].length - 1).toFixed())
                        ];
                    const circle = Bodies.circle(
                        randomeSelectDataDirstPath.x,
                        70,
                        spacingX / 3.5,
                        {
                            // friction: 0.00001,
                            restitution: 0,
                            density: 0.001,
                            frictionAir: 0.042,
                            // frictionAir: 0,

                            friction: 1,
                            frictionStatic: 10,

                            sleepThreshold: 0,
                            label: `ball-${idx}-${randomeSelectDataDirstPath.changeTo}`,
                            angularSpeed: 10,
                            // timeScale: 2,
                            render: {
                                fillStyle: "#ff00ff",
                                visible: true,
                            },
                        }
                    );
                    circle.collisionFilter.category = CATEGORY_BALL;
                    circle.collisionFilter.mask = CATEGORY_PEG;
                    World.add(world, circle);
                    Matter.Body.setAngle(circle, -0.3);

                    return circle;
                };


                let i: any, j: any, lastI: any;
                for (i = 0; i < (row % 2 === 0 ? row + 4 : row + 3); i++) {
                    for (j = 1; j <= i && i <= row + 2; j++) {

                        pegs.push(
                            Bodies.circle(
                                280 + (j * spacingX - i * (spacingX / 2)),
                                20 + i * spacingY,
                                size * collideSetting.pegScale,
                                {
                                    isStatic: true,
                                    render: {
                                        fillStyle: "#fff",
                                        visible: true,
                                    },
                                    label: `pegs-${i}-${j}`,
                                }
                            )
                        );
                    }
                    lastI = i;
                }




                const wallSetting = gotWallData || await (await fetch("/wall.json")).json();
                gotWallData = wallSetting;
                const { startX, spacingXScale } = wallSetting[orientation][row];

                for (i = 0; i < row + 2; i++) {
                    wall.push(
                        Bodies.rectangle(
                            startX +
                            -1 * spacingX +
                            ((row + 6) * spacingX - i * spacingX * spacingXScale),
                            lastI * spacingY + 190,
                            size * 2.5,
                            lastI + 350,
                            {
                                isStatic: true,
                                label: `wall`,
                                collisionFilter: {
                                    category: CATEGORY_PEG,
                                },
                                render: {
                                    fillStyle: "rgb(147,154,176)",
                                    visible: wallSetting.visible,
                                },
                                chamfer: {
                                    radius: [size, size, 0, 0],
                                },
                            }
                        )
                    );
                }
                pegs = pegs.slice(3);
                pegs.map((i: Matter.Body, idx) => {
                    i.collisionFilter.category = CATEGORY_PEG;
                    World.add(world, i);
                    Matter.Body.setAngle(i, -0.3);
                });
                wall.map((i: Matter.Body, idx) => {
                    i.collisionFilter.category = CATEGORY_PEG;
                    World.add(world, i);
                });

            })();
        },
        row: () => row,
        touchPegObj: {} as any,
        stop: () => {
            // Engine.clear(engine);
            // allRunner.map((i) => cancelAnimationFrame(i));

            // World.clear(engine.world, false);

            Matter.Render.stop(render);
            Matter.Runner.stop(runner1);
            Matter.Runner.stop(runner2);

            // Matter.Runner.stop(runner3);
            // render.canvas.remove();
            // // @ts-ignore
            // render.context = null;
            // // @ts-ignore
            // render.canvas = null;
            // render.textures = {};
            // element.innerHTML = "";
            data.touchPegObj = {};
        },
        animate: async (
            items: any,
            onSleep: (data: Result, idx: number) => void
        ) => {
            const collideSetting =
                global_collideSetting || (await (await fetch("/collide.json")).json());
            const delay = collideSetting.delayManual;
            items.map((item: any, idx: number) => {
                data.touchPegObj[idx + ""] = 0;
                let interval: any = undefined;
                interval = setInterval(() => {
                    if (dropBall && interval) {
                        setTimeout(() => {
                            const off = data.singleAnimate(
                                item,
                                (...a: [Result, number]) => {
                                    onSleep(...a);
                                    typeof off === "function" && off();
                                },
                                idx
                            );
                        }, delay * idx);
                        clearInterval(interval);
                        interval = undefined;
                    }
                });
            });
        },
        singleAnimate: (
            item: any,
            onSleep: (data: Result, idx: number) => void,
            i: number
        ) => {
            // const idx = Object.keys(data.touchPegObj).length + i;
            // const sorted = Object.keys(data.touchPegObj).reduce((a, b) => Number(a) > Number(b) ? a : b);
            const idx = Date.now();
            // console.log(sorted, idx);
            // Object.keys(data.touchPegObj).reduce((a, b) => Number(a) > Number(b) ? a : b)[];
            // console.log(idx)
            let finishFired = false;
            const collEvent = async (e: any, off: () => void) => {
                const collideSetting =
                    global_collideSetting ||
                    (await (await fetch("/collide.json")).json());

                if (
                    e.pairs[0].bodyB.label.indexOf(`ball`) == 0 &&
                    e.pairs[0].bodyB.label.indexOf(idx) !== 5
                )
                    return;
                if (
                    e.pairs[0].bodyA.label.indexOf(`ball`) == 0 &&
                    e.pairs[0].bodyA.label.indexOf(idx) !== 5
                )
                    return;

                const ballTemp: Matter.Body =
                    e.pairs[0].bodyB.label.indexOf(`ball`) == 0
                        ? e.pairs[0].bodyB
                        : e.pairs[0].bodyA.label.indexOf(`ball`) == 0
                            ? e.pairs[0].bodyA
                            : null;
                const pegsTemp: Matter.Body =
                    e.pairs[0].bodyB.label.indexOf(`pegs`) == 0
                        ? e.pairs[0].bodyB
                        : e.pairs[0].bodyA.label.indexOf(`pegs`) == 0
                            ? e.pairs[0].bodyA
                            : null;

                if (ballTemp && pegsTemp) {
                    const samePageRow =
                        ballTemp.label.indexOf(
                            pegsTemp.label.split("-").slice(0, 2).join("-")
                        ) > -1;
                    const topPeg = pegsTemp.label.split("-").slice(0, 1);
                    topPeg[1] = Number(topPeg[1]) - 1 + "";
                    const topPageRow = ballTemp.label.indexOf(topPeg.join("-")) > -1;
                    // console.log(samePageRow, topPageRow)
                    if (samePageRow) return;
                    if (topPageRow) return;
                }

                let ballBody: any, pegBody: any;
                // e.pairs[0].collision.penetration = { x: -0.1, y: -0.1 };
                if (
                    e.pairs[0].bodyB.label.indexOf(`finish`) == 0 &&
                    e.pairs[0].bodyA.label.indexOf(`ball`) == 0
                ) {
                    if (!finishFired) {
                        finishFired = true;
                    } else {
                        return;
                    }
                    // requestAnimationFrame(() => {
                    World.remove(world, e.pairs[0].bodyA);
                    delete data.touchPegObj[idx];
                    sound.playHole();

                    onSleep(item, i);

                    off()
                    // })
                    Matter.Engine.update(engine, 1000 / (60))

                    return;
                }
                if (
                    e.pairs[0].bodyB.label.indexOf(`ball`) == 0 &&
                    e.pairs[0].bodyA.label.indexOf(`finish`) == 0
                ) {
                    // requestAnimationFrame(() => {
                    if (!finishFired) {
                        finishFired = true;
                    } else {
                        return;
                    }
                    World.remove(world, e.pairs[0].bodyB);
                    delete data.touchPegObj[idx];
                    sound.playHole();
                    onSleep(item, i);
                    off()
                    // });
                    // Matter.Events.off(engine, "collisionActive", collEvent);
                    Matter.Engine.update(engine, 1000 / (60))

                    return;
                }
                if (
                    e.pairs[0].bodyB.label.indexOf(`ball`) == 0 &&
                    e.pairs[0].bodyA.label.indexOf(`pegs`) == 0
                ) {
                    // e.pairs[0].isActive = false;
                    ballBody = e.pairs[0].bodyB;
                    pegBody = e.pairs[0].bodyA;
                }

                if (
                    e.pairs[0].bodyB.label.indexOf(`pegs`) == 0 &&
                    e.pairs[0].bodyA.label.indexOf(`ball`) == 0
                ) {
                    // e.pairs[0].isActive = false;
                    ballBody = e.pairs[0].bodyA;
                    pegBody = e.pairs[0].bodyB;
                }

                if (!ballBody || !pegBody) return;

                // Matter.Body.setMass(ballBody, 10000);

                const { prize, path } = item;
                const arrDirection: string[] = path.map((a: string) => a);
                arrDirection.shift();
                if (ballBody.label.split("-")[2]) {
                    arrDirection.unshift(ballBody.label.split("-")[2]);
                }

                const touchPeg = data.touchPegObj[idx];

                const jump: any = {
                    R: (ballBody: Matter.Body, pegBody: Matter.Body, idx: number) => {
                        Matter.Body.setStatic(ballBody, false);

                        data.touchPegObj[idx] = data.touchPegObj[idx] + 1;
                        ballBody.label = [
                            ballBody.label.split("-")[0],
                            ballBody.label.split("-")[1],
                            ballBody.label.split("-")[2],
                            "",
                        ]
                            .join("-")
                            .concat(pegBody.label);
                        if (collideSetting.pegColor) {
                            pegBody.render.fillStyle = collideSetting.pegColor;
                            setTimeout(() => {
                                pegBody.render.fillStyle = "#ffffff";
                            }, 0);
                        }

                        // setTimeout(() => {
                        Matter.Body.setAngle(ballBody, -0.3);
                        Matter.Body.setVelocity(ballBody, { x: 0, y: 0 });
                        Matter.Body.setAngularVelocity(ballBody, 0);
                        Matter.Body.applyForce(
                            ballBody,
                            {
                                y: ballBody.position.y,
                                x: ballBody.position.x,
                            },
                            collideSetting[orientation]["R"][row].applyForce
                        );
                        // Matter.Engine.update(engine, 1000 / (60))

                        // }, 0);
                        sound.playBall(Number(randomNumber(0, 2).toFixed()));

                    },
                    L: (ballBody: Matter.Body, pegBody: Matter.Body, idx: number) => {
                        Matter.Body.setStatic(ballBody, false);
                        data.touchPegObj[idx] = data.touchPegObj[idx] + 1;
                        ballBody.label = [
                            ballBody.label.split("-")[0],
                            ballBody.label.split("-")[1],
                            ballBody.label.split("-")[2],
                            "",
                        ]
                            .join("-")
                            .concat(pegBody.label);
                        if (collideSetting.pegColor) {
                            pegBody.render.fillStyle = collideSetting.pegColor;
                            setTimeout(() => {
                                pegBody.render.fillStyle = "#ffffff";
                            }, 0);
                        }
                        // setTimeout(() => {
                        Matter.Body.setAngle(ballBody, -0.3);
                        Matter.Body.setVelocity(ballBody, { x: 0, y: 0 });
                        Matter.Body.setAngularVelocity(ballBody, 0);
                        Matter.Body.applyForce(
                            ballBody,
                            {
                                y: ballBody.position.y,
                                x: ballBody.position.x,
                            },
                            collideSetting[orientation]["L"][row].applyForce
                        );

                        // Matter.Engine.update(engine, 1000 / (60))

                        // }, 0);
                        sound.playBall(Number(randomNumber(0, 2).toFixed()));

                    },
                };

                if (arrDirection[touchPeg]) {

                    // requestAnimationFrame(() => {
                    Matter.Body.setStatic(ballBody, true);
                    jump[arrDirection[touchPeg]](ballBody, pegBody, idx);
                    Matter.Engine.update(engine, 1000 / (60))

                    // })
                }
            };

            // Matter.Events.on(engine, "collisionStart", collEvent);
            //   Matter.Events.on(engine, "collisionActive", collEvent);



            if (!dropBall) {

                dropBallCheck = setInterval(() => {
                    if (dropBall) {
                        clearInterval(dropBallCheck);

                        if ((document.visibilityState == "hidden" || document.hidden) && document.querySelector('.iPhone')) {
                            setTimeout(() => {

                                sound.playHole();
                                onSleep(item, i);
                            }, 500 * row);
                            return;
                        }
                        data.touchPegObj[idx] = 0;
                        const ball = dropBall(idx, item.path[0]);
                        const collQuery = () => {
                            const collisions = Matter.Query.collides(ball, [...pegs, finish]);

                            if (collisions.length > 0) {
                                const e = { pairs: collisions }
                                collEvent(e, () => {
                                    Matter.Events.off(engine, 'beforeUpdate', collQuery);
                                })
                            }
                        }
                        Matter.Events.on(engine, 'beforeUpdate', collQuery);

                    }
                }, 500);
            } else {
                data.touchPegObj[idx] = 0;
                const ball = dropBall(idx, item.path[0]);
                const collQuery = () => {
                    const collisions = Matter.Query.collides(ball, [...pegs, finish]);

                    if (collisions.length > 0) {
                        const e = { pairs: collisions }
                        collEvent(e, () => {
                            Matter.Events.off(engine, 'beforeUpdate', collQuery);
                        })
                    }
                }
                Matter.Events.on(engine, 'beforeUpdate', collQuery);
            }
            return () => {

                // Matter.Events &&
                // Matter.Events.off(engine, "collisionStart", collEvent);
                // Matter.Events.off(engine, "collisionActive", collEvent);
            }
        },
    };

    // data.changeRow(r)
    return data;
};

const randomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}


export default Plinko;
