import { standardCountryLanguageCodes } from "../const/LangKeys"

/**
 * 
 * @param number {number | string}
 * @param currency {string}
 * @param lang {string}
 * @returns string
 */
export const formatCurrency = (number: number | string | undefined, currency: string | undefined, lang: string = 'id'): string => {
	if (!number) return "0"

	if (typeof(number) === 'string') {
		number = Number(number)
	}

	if (isNaN(number)) return "0"

	if (!currency) currency = 'IDR'

	const code: string = standardCountryLanguageCodes[lang]

	let result = ""

	lang = lang.toLowerCase()
	result = (number).toLocaleString(code, {
		style: 'currency',
		currency: currency,
	})	

	return result
}

/**
 * 
 * @param value {number}
 * @returns string
 */
export const formatChipText = (value: number): string=> {
	if (value >= 1000000000) {
		return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
	}
	if (value >= 1000000) {
		return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
	}
	if (value >= 1000) {
		return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
	}

	return String(value)
}

/**
 * 
 * @param amount {number}
 * @param chipBase {number[]}
 * return number
 */
export const chipBet = (amount: number, chipBase: number[]): number => {
	if (chipBase.length === 0) return 0

    const n = chipBase.length
    let idx: number = n - 1

    if (amount >= chipBase[n - 1]) return idx
    
    for (let i = 1; i < chipBase.length; i++) {
        if (amount < chipBase[i]) {
            idx = i - 1
            break
        }
    }

    return idx
}