import React, { Fragment, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import BG from "./bg.png";
import BGHead from "./bg-head.png";
import Close from "./x.png";
import "./index.scss";
import { useAnimateOpening } from "../../betbar/dropdown";
import { motion } from "framer-motion";
import useOrientation from "../../hook/Orientation";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { formatCurrency } from "../../../tool/money";
import { Player } from "../../user/auth/type";
import { getPlayer } from "../../user/auth/reducer";

const Table = ({
  handleOpen,
  active,
  containerRef,
  motionVariant,
}: ReturnType<typeof useAnimateOpening>) => {
  const { t, i18n } = useTranslation();
  const orientation = useOrientation();
  const player = useAppSelector(getPlayer) as Player;
  const history = useAppSelector((state) => state.history.data);
  const last_history = useAppSelector((state) => state.history.last_history);
  const [forTableHistory, setForTableHistory] = useState<typeof history>([]);

  const { player_balance, player_name, player_currency } = player;
  const dispatch = useAppDispatch();
  const animate = useAnimateOpening("table");

  const handleFormatCurrency = (amount: number): string =>
    formatCurrency(amount, player_currency, language);
  const language = i18n.language;

  useEffect(() => {
    try {
      setTimeout(() => {

        setForTableHistory(history)
      }, 500);

    } catch (error) {

    }
  }, [last_history.length]);

  useEffect(() => {
    const scroll = document.getElementById(
      "scroll-range-y"
    ) as HTMLInputElement;

    const panel = document.querySelector(".table-body") as HTMLDivElement;
    const handleInputY = function () {
      const total = panel!.scrollHeight - panel!.offsetHeight;
      scroll.min = "0";
      scroll.max = total.toString();
      panel!.scrollTop = Number(scroll!.value);
    };

    const handleScrollY = function () {
      const total = panel!.scrollHeight - panel!.offsetHeight;
      scroll.min = "0";
      scroll.max = total.toString();
      scroll!.value = panel!.scrollTop.toString();
    };

    scroll.addEventListener("input", handleInputY);
    panel.addEventListener("scroll", handleScrollY);
    return () => {
      scroll.removeEventListener("input", handleInputY);
      panel.removeEventListener("scroll", handleScrollY);
    };
  }, []);

  // useEffect(() => {
  //   const scroll = document.getElementById(
  //     "scroll-range-x"
  //   ) as HTMLInputElement;

  //   const panel = document.querySelector(".table-body") as HTMLDivElement;
  //   const panel2 = document.querySelector(
  //     ".table-head-scroll"
  //   ) as HTMLDivElement;
  //   const handleInputX = function () {
  //     const total = panel!.scrollWidth - panel!.offsetWidth;
  //     scroll.min = "0";
  //     scroll.max = total.toString();
  //     panel!.scrollLeft = Number(scroll!.value);
  //     panel2!.scrollLeft = Number(scroll!.value);
  //   };

  //   const handleScrollX = function () {
  //     const total = panel!.scrollWidth - panel!.offsetWidth;
  //     scroll.min = "0";
  //     scroll.max = total.toString();
  //     scroll!.value = panel!.scrollLeft.toString();
  //     panel2!.scrollLeft = panel!.scrollLeft;
  //   };
  //   scroll.addEventListener("input", handleInputX);
  //   panel.addEventListener("scroll", handleScrollX);
  //   return () => {
  //     scroll.removeEventListener("input", handleInputX);
  //     panel.removeEventListener("scroll", handleScrollX);
  //   };
  // }, []);
  const { viewAll } = useAppSelector((state) => state.history);

  useEffect(() => {
    if (viewAll) {
      handleOpen();
    }
  }, [viewAll]);

  return (
    <motion.div
      initial="close"
      animate={active ? "open" : "close"}
      variants={motionVariant}
      // @ts-ignore
      ref={containerRef}
      className="table-container"
    >
      {" "}
      <div className="black" onClick={() => handleOpen()}></div>
      <img className="table-bg" alt="" src={BG} />
      <div className="table-title">
        <span></span>
        <div className="title">{t("history.table.title")}</div>
        <img
          title={t("history.table.close")!}
          className="close"
          alt=""
          src={Close}
          onClick={() => handleOpen()}
        />
      </div>
      <div className="table">
        <div
          className="table-head"
          style={{ backgroundImage: `url('${BGHead}')` }}
        >
          {/* <img className="head-bg" alt="" src={BGHead} /> */}
          <div className="table-head-scroll">
            <div className="head-list">
              <div className="list-item td-date">{t("history.table.date")}</div>
              <div className="list-item td-period">
                {t("history.table.period")}
              </div>
              <div className="list-item td-ball">{t("history.table.ball")}</div>
              <div className="list-item td-totalbet">
                {t("history.table.totalbet")}
              </div>
              <div className="list-item td-payout">
                {t("history.table.payout")}
              </div>
              <div className="list-item td-icon"></div>
            </div>
          </div>
        </div>
        <div className="table-body">
          <table>
            <tbody>
              {forTableHistory.map((items, idx) => {
                const grouped: any = {};
                items.result.map((i) => {
                  if (!grouped[i.prize.value]) {
                    grouped[i.prize.value] = [i];
                  } else {
                    grouped[i.prize.value] = [...grouped[i.prize.value], i];
                  }
                });
                return (
                  <Fragment
                    key={"history-table".concat(
                      items.round_id
                        .toString()
                        .concat("first")
                        .concat(idx.toString())
                    )}
                  >
                    <tr>
                      <td align="center" className={"td-date"}>
                        {items.created_date}
                      </td>
                      <td align="center" className={"td-period"}>
                        {items.round_id}
                      </td>
                      <td align="center" className={"td-ball"}>
                        {items.result.length}
                      </td>
                      <td align="center" className={"td-totalbet"}>
                        {handleFormatCurrency(items.data.total_amount)}
                      </td>
                      <td align="center" className={"td-payout"}>
                        {handleFormatCurrency(items.data.total_win)}
                      </td>
                      <td
                        align="center"
                        className={"td-icon"}
                        onClick={() => {
                          animate.handleClose(() => { });
                          animate.open(animate.active === idx ? -1 : idx);
                        }}
                      >
                        {animate.active === idx ? "▲" : "▼"}
                      </td>
                    </tr>
                    <tr style={{ borderTop: animate.active === idx ? '0' : '1px solid black' }}>
                      <td colSpan={6} align={"center"}>
                        <motion.div
                          initial="close"
                          animate={animate.active === idx ? "open" : "close"}
                          variants={animate.motionVariant}
                          // @ts-ignore
                          ref={animate.containerRef}
                          className="retable"
                          key={"history-table-item".concat(
                            idx.toString().concat(idx.toString())
                          )}
                        >
                          <table>
                            <thead>
                              <tr>
                                <div className="head-list">
                                  <th className="list-item td-betperball">
                                    {t("history.table.betperball")}
                                  </th>
                                  <th className="list-item td-ball">
                                    {t("history.table.ball")}
                                  </th>
                                  <th className="list-item td-multiplier">
                                    {t("history.table.multiplier")}
                                  </th>
                                  <th className="list-item td-payout">
                                    {t("history.table.payout")}
                                  </th>
                                </div>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(grouped).map((key) => {
                                const RenderDiv = () => <div className="head-list" key={Date.now().toString()}>
                                  <td className="list-item td-betperball">
                                    {handleFormatCurrency(
                                      items.data.detail_bet.amount
                                    )}
                                  </td>
                                  <td className="list-item td-ball">
                                    {grouped[key].length}
                                  </td>
                                  <td className="list-item td-multiplier">
                                    {key}x
                                  </td>
                                  <td className="list-item td-payout">
                                    {handleFormatCurrency(
                                      items.data.detail_bet.amount *
                                      Number(key) * grouped[key].length
                                    )}
                                  </td>
                                </div>
                                return (
                                  <React.Fragment key={key.concat(Date.now().toString())}>
                                    <tr>
                                      <RenderDiv />
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </motion.div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="slider-y">
          <form className="rangeSlider">
            <input
              className="scroll-range"
              id="scroll-range-y"
              type="range"
              defaultValue={"0"}
            />
          </form>
        </div>
        
      </div>
    </motion.div>
  );
};

export default Table;
