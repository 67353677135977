import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { Endpoints } from "../../const/Api";
import { RootState } from "../../store";
import { client } from "../../tool/client";
import {
  endAuto,
  finishAnimation,
  forcefinishAuto,
  resetResult,
  sendAutoBetAction,
} from "../result/reducer";
import { History, HistoryFull } from "./type";

interface InitialState {
  current_round: number;

  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
  data: HistoryFull[];
  last_history: History[];
  viewAll: boolean;
}

const historyAdapter = createEntityAdapter<History>({
  selectId: (history) => history.round_id,
  sortComparer: (a, b) => b.round_id - a.round_id,
});

const initialState: InitialState = {
  current_round: 1,
  status: "idle",
  error: null,
  data: [],
  last_history: [],
  viewAll: false,
};

let domain: any;

export const fetchHistory = createAsyncThunk(
  "history/fetchHistory",
  async () => {
    const response = await new Promise((r, rj) => {
      setTimeout(async () => {
        try {

          domain = domain || await (await fetch(Endpoints.Domain)).json();

          const response = await client.get(domain.history);
          if (!response.data.data) {
            rj("data history empty");
          }
          r(response);
        } catch (error) {

        }
      }, 1000);
    });

    return (response as any).data.data;
  }
);
let i: any;
export const refreshHistory = createAsyncThunk(
  "history/refreshHistory",
  async (auto: boolean, { dispatch, getState }: any) => {
    const state: any = getState();
    let v: any = 0;

    if (state.result.auto) {
      // setTimeout(() => {

      if (i) {
        clearInterval(i);
        i = undefined;
      }
      i = setInterval(() => {
        v = 100 + v;
        //  console.log(v)
        if (
          getState().result.payload[getState().result.singleCount] && ( getState().auth.player.player_balance >=
          getState().result.payload[getState().result.singleCount].last_bet
            .total_amount)
        ) {
          // console.log(getState().result.singleCount, getState().result.payload[getState().result.singleCount].last_bet)
          dispatch(
            sendAutoBetAction({
              ...getState().result.payload[getState().result.singleCount]
                .last_bet,
              button_bet: {
                ...getState().result.payload[getState().result.singleCount]
                  .last_bet.button_bet,
                round_id:
                  getState().result.payload[getState().result.singleCount]
                    .last_bet.button_bet.round_id + 1,
              },
              round_id:
                getState().result.payload[getState().result.singleCount]
                  .last_bet.button_bet.round_id + 1,
            })
          );
          clearInterval(i);
          i = undefined;
        } else {
          if (getState().result.inQueue == 0 && getState().result.auto) {
            dispatch(forcefinishAuto());
            clearInterval(i);
            i = undefined;
          }
        }
      }, 0);
      // }, setting.delay);
    } else {
      // dispatch(refreshHistory(true));

    }

    const response = await new Promise((r, rj) => {
      setTimeout(async () => {
        domain = domain || await (await fetch(Endpoints.Domain)).json();
        const response = await client.get(domain.history);

        if (!response.data.data) {
          rj("data history empty");
        }
        r(response);
      }, 1000);
    });
    console.log(getState().result.auto, (response as any).data.data[0].round_id, "history auto");
    return {
      data: (response as any).data.data, auto: auto, last_round: getState().result.payload[getState().result.singleCount]
        .last_bet.button_bet.round_id + 1
    };
  }
);

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addedHistory: (state, action: PayloadAction<History>) => {
      // historyAdapter.addOne(state, action.payload)
      // state.data = [...state.data, action.payload]
    },
    addedLastHistory: (state, action: PayloadAction<History>) => {
      state.last_history = [...state.last_history, action.payload];
      // historyAdapter.addOne(state, action.payload)
    },
    clearLastHistory: (state, action: PayloadAction) => {
      // historyAdapter.addOne(state, action.payload)
      state.last_history = [];
    },
    closeViewAll: (state, action: PayloadAction) => {
      state.viewAll = false;
    },
    openViewAll: (state, action: PayloadAction) => {
      state.viewAll = true;
      // state.viewAll = !state.viewAll ;
    },
    newRound: (state, action: PayloadAction) => {
      state.current_round = state.current_round + 1;
      // state.viewAll = !state.viewAll ;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHistory.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(
        fetchHistory.fulfilled,
        (state, action: PayloadAction<HistoryFull[]>) => {
          state.status = "succeeded";

          const lastHistory = action.payload[0];
          if (lastHistory) {
            state.current_round = lastHistory.round_id + 1;
          }

          state.data = action.payload;
        }
      )
      .addCase(fetchHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(
        refreshHistory.fulfilled,
        (state, action: PayloadAction<{ data: HistoryFull[], auto: boolean, last_round: number }>) => {
          state.status = "succeeded";

          const lastHistory = action.payload.data[0];

        
          

          if (action.payload.auto) {
            if (state.current_round < action.payload.last_round) {
              state.current_round = action.payload.last_round;
            } 


            
          }
          if (lastHistory) {
            // state.current_round = lastHistory.round_id + 1;
            if(!action.payload.auto && (state.current_round < lastHistory.round_id + 1)) {
              state.current_round = lastHistory.round_id + 1;
  
            }
  
            if(!action.payload.auto && (state.current_round > lastHistory.round_id + 1)) {
              state.current_round = lastHistory.round_id + 1;
            }
          }

          state.data = action.payload.data;
        }
      );
  },
});

export const {
  addedHistory,
  clearLastHistory,
  addedLastHistory,
  openViewAll,
  newRound,
  closeViewAll,
} = historySlice.actions;

export default historySlice.reducer;
