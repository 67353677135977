import React from 'react'
import Background from './bg.png'

const Portrait = () => {
    return (
        <>
            <img alt='' src={Background} className='background-image' />
        </>
    )
}

export default Portrait