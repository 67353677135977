import React from "react";

import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hook";
import { formatCurrency } from "../../tool/money";
import { LimitBetKeys } from "../user/auth/type";
import "./index.scss";
import bg from "./table-info.png";

const limitInfoKeys: LimitBetKeys[] = ["minimal", "maximal"];

const HeaderInfo = ({handleOpen}: any) => {
  const { t, i18n } = useTranslation();

  const limit_bet = useAppSelector(
    (state) => state.auth.game?.limit_bet
  ) as Record<LimitBetKeys, number>;
  const player_currency = useAppSelector(
    (state) => state.auth.player?.player_currency
  ) as string;
  const current_round = useAppSelector(
    (state) => state.history.current_round
  ) as number;

  const language = i18n.language;

  return (
    <div className="header">
        {/* <div className="black" 
    // onClick={()=>handleOpen()} 
    > */}

    {/* </div> */}
      <img className="bg-info" alt="" src={bg} />
      <div className="header-info">
        <div className="info-period">
          <span className="info-period-text">P: {current_round}</span>
        </div>

        <div className="info-limit-bet">
          {limit_bet &&
            limitInfoKeys?.map((key: LimitBetKeys) => (
              <div key={key} className="info-limit-bet-items">
                <div className="info-limit-text">
                  {t(`info.${key}`)}:{" "}
                  {formatCurrency(limit_bet[key], player_currency, language)}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderInfo;
