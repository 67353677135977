import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Player } from "../auth/type";

import BGWinLandscape from "./bg-win-landscape.png";
import BGWinPortrait from "./bg-win-portrait.png";
import UserIcon from "./user.png";
import BalanceIcon from "./balance.png";

import { useAppSelector } from "../../../store/hook";
import { formatCurrency } from "../../../tool/money";
import { getPlayer } from "../auth/reducer";
import { getTotalBets } from "../../betbar/reducer";
import "./footer.scss";
import useOrientation from "../../hook/Orientation";
import AutoAjustText from "./autoText";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const orientation = useOrientation();
  const player = useAppSelector(getPlayer) as Player;
  const auto = useAppSelector((state) => state.result.auto);
  const { total_amount_auto, balls } = useAppSelector((state) => state.playerbet);
  const total_amount = useAppSelector(getTotalBets) as number;

  const sendbetStatus = useAppSelector((state) => state.result.status);
  const totalwinResult = useAppSelector(
    (state) => state.result.total_win
  ) as number;

  const { player_balance, player_name, player_currency } = player;

  const language = i18n.language;

  const handleFormatCurrency = (amount: number): string =>
    formatCurrency(amount, player_currency, language);

  const displayPlayerBalance = useMemo(
    () => formatCurrency(player_balance, player_currency, language),
    [player_balance, player_currency, total_amount, totalwinResult, language]
  );

  const lengthInfo = player_name.length + displayPlayerBalance.length;

  return (
    <div className="footer">
      <div className={`footer-info${lengthInfo > 30 ? " footer-info-sm" : ""}`}>
        <div className="footer-info-content">
          <img className="footer-info-icon" alt="" src={UserIcon} />

          <span className="footer-info-user-value">{player_name}</span>
        </div>

        <div className="footer-info-content">
          <img className="footer-info-icon" alt="" src={BalanceIcon} />

          <span className="footer-info-balance-value">
            {displayPlayerBalance}
          </span>
        </div>
      </div>
      <div className="footer-total-win">
        <img
          className="footer-total-win-bg"
          alt=""
          src={orientation == "landscape" ? BGWinLandscape : BGWinPortrait}
        />

        <AutoAjustText className="footer-total-win-content" text={handleFormatCurrency(totalwinResult)} />
      </div>

      <div className="footer-total-amount">
        <div className="footer-total-amount-content">
          <span className="footer-total-amount-text capitalize">
            {t("footer.bet")}:
          </span>
          <span className="footer-total-amount-value">
            {handleFormatCurrency(total_amount_auto)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
