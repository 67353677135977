export const BASE_URL = window.location ||
"http://192.168.31.234:3001" ||
//  "http://localhost:3001" ||
  "http://192.168.100.8:3001" || 'https://roulette-dev.saibara619.xyz'

export enum Endpoints {
    Auth = '/auth.json',
    History = '/history.json',
    SendBet = '/game/send_bet',
    Domain = '/domain.json',
    Settings = '/game/settings/properties'
}