import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ResultResponse, ResultState, SendBet } from "./type";
import { updateBalance } from "../user/auth/reducer";
import { client } from "../../tool/client";
import { Endpoints } from "../../const/Api";
import Bugsnag from "@bugsnag/browser";
import { newRound } from "../history/reducer";
import { updateTotalAmountAuto, updateTotalAmountManual } from "../betbar/reducer";

const initialState: ResultState = {
  result: [],
  total_win: 0,
  payload: {},
  bet_win: [],
  singleCount: -1,
  inQueue: 0,
  status: "idle",
  error: null,
  auto: false,
};

let domain: any;
export const sendBetAction = createAsyncThunk(
  "result/sendbet",
  async (params: SendBet, { dispatch, getState }) => {
    try {
      const state: any = getState()
      if (state.result.status === 'start-spin') throw Error("Can't get result in twice!");
      // state.status
      // const response = await client.get(Endpoints.SendBet, params)
      domain = domain || await (await fetch(Endpoints.Domain)).json();

      if (domain.useGenerate) {

        const response = await client.get(`${domain.generate}?balls=${params.button_bet.balls}&risk=${params.button_bet.risk.toLowerCase()}&rows=${params.button_bet.rows}&amount=${params.button_bet.amount}`);
        dispatch(updateBalance(-params.total_amount));

        return { ...response.data, last_bet: params };

      } else {
        const response = await client.post(`${domain.sendbet}`, params);
        if (response.data.status == true) {
          dispatch(updateTotalAmountManual(params.total_amount));
          dispatch(updateBalance(-params.total_amount));
          dispatch(newRound());
        } else {
          if ((response.data.message as string).toLowerCase().includes("there is an error with game period")) {
            dispatch(newRound());

          }
          // dispatch(updateTotalAmountManual(-params.total_amount));
          // dispatch(updateBalance(params.total_amount));
        }

        return { ...response.data.data, last_bet: params };

      }
      // return response.data
    } catch (error: any) {
      if ((error.message as string).toLowerCase().includes("there is an error with game period")) {
        // dispatch(newRound());

      }      Bugsnag.notify(error)
      throw Error(error?.message);
    }
  }
);

export const sendAutoBetAction = createAsyncThunk(
  "result/sendautobet",
  async (params: SendBet, { dispatch, getState }) => {
    try {
      const state: any = getState();
      if (!state.result.auto) throw Error("Auto bet cancelled");
      // const response = await client.get(Endpoints.SendBet, params)
      domain = domain || await (await fetch(Endpoints.Domain)).json();
      // const sendbetParam = {...params, round_id: params.button_bet.round_id!+1}
      if (domain.useGenerate) {

        const response = await client.get(`${domain.generate}?balls=${params.button_bet.balls}&risk=${params.button_bet.risk.toLowerCase()}&rows=${params.button_bet.rows}`);
        if (response.status)

          dispatch(updateBalance(-params.total_amount));

        return new Promise((r) => {
          setTimeout(() => {
            r(
              { ...response.data, last_bet: params }
            )
          }, 500);
        })

      } else {
        const response = await client.post(`${domain.sendbet}`, params);
        if (response.status) {
          dispatch(updateBalance(-params.total_amount));

          dispatch(updateTotalAmountAuto(params.total_amount));
        }

        return { ...response.data.data, last_bet: params };
      }
      // return response.data
    } catch (error: any) {
      // dispatch(endAuto())
      Bugsnag.notify(error);
      if (error.message.toLowerCase().indexOf("load failed") > -1) {
        setTimeout(() => {
          dispatch(sendAutoBetAction(params));
        }, 1000);
      }
      // else if(error.message.toLowerCase().indexOf("auto bet cancelled")>-1){

      // }
      else throw Error(error?.message);
    }
  }
);

export const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    resetResult: (state, action: PayloadAction<boolean>) => {
      state.status = "idle";
      state.result = [];
      state.bet_win = [];
      state.payload = {};

      if (action.payload == true) {
        state.total_win = 0;

      }

      state.singleCount = -1;
      state.auto = false;
    },
    updateTotalWin: (state, action: PayloadAction<number>) => {
      state.total_win += action.payload;
    },
    finishAnimation: (state, action: PayloadAction<number | undefined>) => {
      state.status = "end-spin";
    },
    showWin: (state, action: PayloadAction) => {
      state.status = "show-win";
    },
    resultFinished: (state, action: PayloadAction) => {
      state.status = "finished";
    },
    startAnimation: (state, action: PayloadAction) => {
      state.status = "start-spin";

    },
    continueAnimation: (state, action: PayloadAction) => {
      state.status = "continue-spin";

    },
    endAuto: (state, action: PayloadAction) => {
      state.auto = false;
    },
    startAuto: (state, action: PayloadAction) => {
      state.auto = true;
    },
    forcefinishAuto: (state, action: PayloadAction) => {
      state.status = "force-end-spin";

    },
  },
  extraReducers(builder) {
    builder
      .addCase(sendBetAction.pending, (state, action) => {
        state.status = "loading";
        state.result = [];
        state.bet_win = [];
        // state.total_win = 0;
      })
      .addCase(
        sendBetAction.fulfilled,
        (state, action: PayloadAction<ResultResponse>) => {
          state.status = "succeeded";
          state.result = action.payload.game_result.result as any;
          state.payload = action.payload as any;
        }
      )
      .addCase(sendBetAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }).addCase(sendAutoBetAction.pending, (state, action) => {
        state.status = "loading";
        state.singleCount = state.singleCount + 1;
        state.inQueue = state.inQueue + 1;
      })
      .addCase(
        sendAutoBetAction.fulfilled,
        (state, action: PayloadAction<ResultResponse>) => {
          state.status = "succeeded";
          state.result = action.payload.game_result.result as any;
          // if(state.singleCount==9){
          //   state.singleCount = -1
          // }

          state.payload[state.singleCount] = action.payload as any;

        }
      )
      .addCase(sendAutoBetAction.rejected, (state, action) => {
        // Todo: implement No exception
        if (action.error.message!.toLowerCase().indexOf('load failed') > -1) {
          state.status = "retry-send-auto";

        }
        else if (action.error.message!.toLowerCase().indexOf('auto bet cancelled') > -1) {
          state.status = "cancel-send-auto";
        }
        else {
          state.status = "failed";
          state.error = action.error.message;
        }
      });
  },
});

export const {
  resetResult,
  finishAnimation,
  showWin,
  resultFinished,
  startAnimation,
  endAuto,
  startAuto,
  updateTotalWin,
  forcefinishAuto,
  continueAnimation
} = resultSlice.actions;

export default resultSlice.reducer;
