import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { formatCurrency } from "../../tool/money";
import useOrientation from "../hook/Orientation";
import BGPortrait from "./bg-portrait.png";
import FramePortrait from "./frame-portrait.png";

import BGLandscape from "./top_16_rows.png";
import FrameLandscape from "./bottom_16_rows.png";

import Hole from "./hole";

import PlinkoIos from "./PlinkoSAT";
import "./index.scss";
import { Endpoints } from "../../const/Api";
import {
  addedHistory,
  addedLastHistory,
  refreshHistory,
} from "../history/reducer";
import {
  continueAnimation,
  finishAnimation,
  resetResult,
  sendBetAction,
  startAnimation,
  updateTotalWin,
} from "../result/reducer";
import { updateBalance } from "../user/auth/reducer";
import { Result } from "../result/type";
import { updateTotalAmountAuto } from "../betbar/reducer";
import { debounce } from "../../tool/debounce";
let clearAuto: any;
let collideSetting: any;
let singleCountLoc = 0;
let toPlinko: any
  = [];

const Game = () => {
  const { t, i18n } = useTranslation();
  const orientation = useOrientation();
  const language = i18n.language;
  const canvasRef = useRef(null);
  const { rows, risk, amount, balls, total_amount, round_id } = useAppSelector(
    (state) => state.playerbet
  );
  const { result, status, total_win, payload, singleCount, auto } =
    useAppSelector((state) => state.result);
  const [plinkoInstance, setPlinkoInstance] = useState<any>();
  // const [machine, setMachine] = useState<any>(false);
  const [old_plinkoInstance, old_setPlinkoInstance] = useState<any>();
  const [resultState, setResultState] = useState<any>([]);
  const dispatch = useAppDispatch();
  const playerbet = useAppSelector((state) => state.playerbet);

  toPlinko = result;

  useEffect(() => {
    // @ts-ignore
    if (!document.querySelector("canvas")) {

      const plinko = PlinkoIos.create(canvasRef.current, Number(rows), false);
      dispatch(resetResult(false));
      setPlinkoInstance(plinko);

    }

  }, []);

  useEffect(() => {
    if (plinkoInstance) {
      dispatch(resetResult(false));
      plinkoInstance.changeRow(rows)
      singleCountLoc = 0;
      toPlinko = [];
    }
  }, [rows, risk, amount, plinkoInstance]);



  useEffect(() => {
    if (toPlinko.length && status === "succeeded") {
      dispatch(startAnimation());

      (async () => {
        collideSetting = collideSetting || await (await fetch("/collide.json")).json();
        setTimeout(() => {
          dispatch(refreshHistory(auto));
        }, 1000);
      })();

    }
  }, [
    toPlinko,
    toPlinko?.length,
    dispatch,
    status,
    auto
  ]);

  useEffect(() => {
    try {
      // console.log(toPlinko, singleCountLoc);

      if (toPlinko.length && plinkoInstance) {
        if (toPlinko.length === 1) {
          // const plinko = Plinko.create(canvasRef.current, Number(rows), false);

          singleCountLoc = singleCountLoc + 1;
          setTimeout(() => {
            dispatch(continueAnimation());

          }, 500);
          const off = plinkoInstance.singleAnimate(
            toPlinko[0],
            (data: Result) => {
              let new_round_id = payload.round_id;
              const newHistory = {
                idgameresult: 335,
                round_id: new_round_id,
                player_id: "3776914",
                agent_id: "125",
                now: Date.now(),
                created_date: "2022-09-20 10:52:37",
                data: {
                  detail_bet: {
                    rows,
                    risk,
                    balls,
                    round_id: new_round_id,
                    amount: amount,
                  },
                  total_amount: total_amount,
                  total_win: data.prize.value * amount,
                  last_balance: 6926712,
                },
                ...data,
              };
              // 
              dispatch(updateBalance(total_amount * data.prize.value));

              dispatch(updateTotalWin(data.prize.value * amount));

              // dispatch(updateTotalWin(data.prize.value * amount));

              dispatch(addedLastHistory(newHistory));
              console.log(status);
              singleCountLoc = singleCountLoc - 1;
              debounce(() => {
                console.log(singleCountLoc);
                if (singleCountLoc < 1) {

                  dispatch(finishAnimation(singleCountLoc))
                  console.log(status);

                }
              }, 1500)();

              off();
            },
            singleCount
          );
        }

      }
    } catch (e) {
      console.log(e)
    }

  }, [
    toPlinko,
    toPlinko?.length,
    dispatch,
    // status,
    plinkoInstance,
    balls,
    rows,
    risk,
    amount,
    total_amount,
    payload,
    // singleCountLoc,
  ]);

  return (
    <div className="game">
      <img
        className="game-bg big"
        alt=""
        src={orientation == "landscape" ? BGLandscape : BGPortrait}
      />
      <div className="game-frame border-frame"></div>
      <img
        className="game-frame big"
        alt=""
        src={orientation == "landscape" ? FrameLandscape : FramePortrait}
      />

      <style >
        {
          `
    .landscape .game-canvas {
      position: absolute;
      left: 24.6rem;
      width: 50%;
      display: flex;
      justify-content: center;
      padding-left: 1.5rem;
      top: -2rem;
    }
    .landscape  canvas {
        width: 100%;
        z-index: 4;
        position: absolute;
    }
    .portrait
    .game-canvas {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-left: 1.5rem;
      top: 30rem;
      
  }
  .portrait .game-canvas canvas {
    width: 100%;
    z-index: 4;
    position: absolute;

}
  `
        }
      </style>
      <div ref={canvasRef} className="game-canvas"></div>
      <Hole />
    </div>
  );
};

export default Game;
