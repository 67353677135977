import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import BGDropdown from "./dropdown.png";
import { motion } from "framer-motion";

interface DropDownIProps {
  label: string;
  options: string[] | number[];
  setSelected?: (value: string | number) => void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  defaultValue?: string | number;
}


function useOutsideClick(
  ref: any,
  onClickOut: () => void,
  arrayDeps: any[]
) {
  useEffect(() => {
    const onClick = ({ target }: any) => {
      ref && !ref.contains(target) && onClickOut();
    }
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    }
  }, [arrayDeps]);
}

export function useAnimateOpening(display: string) {

  const [active, setActive] = useState<boolean | number>(false);
  const [opening, setOpening] = useState<boolean>(false);

  const toggleDropDown = {
    open: {
      opacity: 1,
      display,
      // height:'auto',
      transition: {
        duration: 0.5,
      },
    },
    close: {
      opacity: 0,
      display: 'none',
      // height: "0",
      transition: {
        duration: 0.3,
      },
    },
  };
  const containerRef = useRef();
  useOutsideClick(
    containerRef.current!,
    () => {
      if (active && !opening) setActive(false);
    },
    [active, opening, containerRef.current]
  );
  const handleOpen = (onClose: any = false, activeValue: boolean | number = false) => {
    if (!opening && active === false) {
      setOpening(true);
      setActive(activeValue === false ? true : activeValue);
    } else {
      setOpening(false);
      setActive(false);
      onClose && typeof onClose === "function" && onClose();
    }
  };

  const handleClose = (onClose: any) => {
    setOpening(false);
    setActive(false);
    onClose && onClose()
  }

  useEffect(() => {
    if (opening) {
      setTimeout(() => {
        setOpening(false);
      }, 500);
    }
  }, [opening]);
  return {
    handleOpen, handleClose, active, containerRef, motionVariant: toggleDropDown, open: (idx: number) => {
      // handleClose()
      setOpening(true);
      setActive(idx);
    }
  }
}

const DropDown = ({ label, options, setSelected, style, className, defaultValue, disabled = false }: DropDownIProps) => {

  const defaultSelected = defaultValue || options[0]
  const [selected, setInternalSelected] = useState(defaultSelected)
  const { handleOpen, active, containerRef, motionVariant } = useAnimateOpening('block')



  const handleSelect = (option: string | number) => {
    setSelected && setSelected(option);
    setInternalSelected(option)
    handleOpen()
  };


  return (
    <div className={"form-group ".concat(className || "")} style={{ ...style }}>
      <label htmlFor={label}>{label}</label>
      <input
        type="text"
        id={label}
        name={label}
        value={selected}
        onFocus={({ target, preventDefault }) => {
          // preventDefault()
          target.blur()
          if (disabled) return;

          handleOpen()
        }}
        onChange={() => { }}
      />
      <div className={"bet-form-tool-list ".concat(disabled?'disabled':'')} >
        <div className="tool-item" >
          <img className="tool-bg-dropdown" alt="" src={BGDropdown} onClick={() => {
            if (disabled) return;

            handleOpen()
          }} />
        </div>
      </div>

      {/* <span ref={ref}> */}
      <motion.div
        // @ts-ignore
        ref={containerRef}
        className="dropdown-select"
        initial="close"
        animate={active ? "open" : "close"}
        variants={motionVariant}
      >
        {options.map((option, idx) => {
          return (
            <div
              key={'option-'.concat(idx.toString())} onClick={() => handleSelect(option)} className="select-item">
              <span>{option}</span>
            </div>
          );
        })}
      </motion.div>
      {/* </span> */}
    </div>
  );
};

export default DropDown;
