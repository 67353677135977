let timerId: any;
function debounce(callback: () => void, wait: number) {
  return () => {
    if (timerId) {

    } else {
      timerId = setTimeout(() => {
        console.log("deebounce")
        callback();
        clearTimeout(timerId);
        timerId = undefined;
      }, wait);
    }
  };
}

export { debounce };
